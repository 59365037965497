import React from 'react';
import { navigate } from 'gatsby';
import { makeStyles, Backdrop, Typography, IconButton, ButtonBase } from '@material-ui/core';

import CloseIcon from 'src/assets/icons/close.svg';

import MainPage from './index';

const useStyles = makeStyles(() => ({
  backdrop: {
    backgroundColor: ' rgba(35, 35, 35, 0.9)',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default function () {
  const classes = useStyles();
  return (
    <>
      <MainPage />
      <Backdrop open className={classes.backdrop}>
        <div>
          <div className={classes.buttonWrapper}>
            <IconButton
              onClick={() => {
                navigate('/');
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>

          <Typography align="center" variant="h1" color="textSecondary" paragraph>
            Thank you <br />
            for your order!
          </Typography>
          <Typography variant="h6" align="center" color="textSecondary" gutterBottom>
            Our team will send you a mail to show you the progress 🙂
          </Typography>
          <Typography variant="h6" align="center" color="textSecondary">
            If you have a question: hello@designondemand.co
          </Typography>
        </div>
      </Backdrop>
    </>
  );
}
